import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Parallax } from 'react-parallax';
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Hero from '../components/Hero';
import Testimonials from '../components/Testimonials';
import useSiteMetadata from '../components/SiteMetadata';

export const AboutPageTemplate = ({ title, image, content, contentComponent, testimonials, helmet }) => {
  const PageContent = contentComponent || Content

  return (
    <>
      {helmet || ''}

      <Parallax bgImage={image} strength={1000}>
        <div className="cover-container">
            <Hero className="cover">
              <h1>{title}</h1>
            </Hero>
        </div>
      </Parallax>

      <div className="section">
          <div className="section__inner">
              <div className="container">
                  <div className="content-container content-container--long-form">
                    <PageContent className="content" content={content} />
                  </div>
              </div>
          </div>
      </div>

      <Parallax bgImage={image} strength={1000} style={{ overflow: 'visible' }}>
        <section className="section section--window">
            <div className="section__inner">
                <div className="container">
                    <h2>What they&#8217;re saying</h2>
                    <Testimonials testimonials={testimonials} isReverse={true}/>
                </div>
            </div>
        </section>
      </Parallax>
    </>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { page, footerData, headerData } = data;
  const { title } = useSiteMetadata();

  return (
    <Layout footerData={footerData} headerData={headerData}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        content={page.html}
        image={page.frontmatter.image.publicURL}
        testimonials={page.fields.testimonials}
        helmet={
          <Helmet titleTemplate={`%s | ${title}`}>
            <title>{`${page.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${page.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      fields {
        testimonials {
          html
          frontmatter {
            name
            citation
          }
        }
      }
      frontmatter {
        title
        description
        image {
          publicURL
        }
      }
    }
    ...LayoutFragment
  }
`

export default AboutPage
